<template>
  <div class="palm2">
    <el-container>
      <!-- 页头 -->
      <el-header>
        <div class="maintitle">
          <img src="../../assets/img/palmmain1.png" @click="palm0" />
          <div @click="palm0">首页</div>
          <div @click="palm1">产品介绍</div>
          <div class="active" @click="palm2">功能介绍</div>
          <div @click="palm3">下载</div>
        </div>
      </el-header>
      <!-- 页面主体内容 -->
      <el-main>
        <div class="palmmain"></div>
        <div class="valback1">
          <div class="valinfo1">
            <img src="../../assets/img/palm2_1.png" />
            <div class="valtext">
              <div class="tal">账号登录</div>
              <div class="tel">
                <div></div>
                <div>
                  支持网吧后台-安全设置-授权手机号、添加的手机号登录，该账号可关联对应的场所信息
                </div>
              </div>
              <div class="tel">
                <div></div>
                <div>
                  支持连锁在线支付预留手机号登录，该账号可查看连锁下的所有场所信息
                </div>
              </div>
              <div class="tel">
                <div></div>
                <div>
                  支持非经营者用户手机号登录，该账号可查看资讯信息，以及发布求购、出售、招聘等信息
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="valback2">
          <div class="valinfo2">
            <div class="valtext">
              <div class="tal">多场所管理</div>
              <div class="tel">
                <div></div>
                <div>支持多场所、多股东模式，一个账号可同时管理多个场所</div>
              </div>
              <div class="tel">
                <div></div>
                <div>
                  通过首页可直接查看账号关联的所有场所当前上座率、今日上机人次、今日收入信息
                </div>
              </div>
              <div class="tel">
                <div></div>
                <div>场所列表支持按不同方式排序查看</div>
              </div>
            </div>
            <img src="../../assets/img/palm2_2.png" />
          </div>
        </div>
        <div class="valback3">
          <div class="valinfo3">
            <img src="../../assets/img/palm2_3.png" />
            <div class="valtext">
              <div class="tal">经营数据</div>
              <div class="tel">
                <div></div>
                <div>
                  提供班次收入、在线收入、网费赠送等营业收入统计，支持查询近3个月数据
                </div>
              </div>
              <div class="tel">
                <div></div>
                <div>
                  提供会员概况、上座率、上机时长、上机人次等经营数据分析，支持按客户机区域查看
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="valback4">
          <div class="valinfo4">
            <div class="valtext">
              <div class="tal">手机提现</div>
              <div class="tel">
                <div></div>
                <div>单网吧、连锁网吧在线支付收入可手机提现，更加方便快捷</div>
              </div>
              <img src="../../assets/img/palm2_9.png" />
            </div>
            <img src="../../assets/img/palm2_4.png" />
          </div>
        </div>
        <div class="valback5">
          <div class="valinfo5">
            <img src="../../assets/img/palm2_5.png" />
            <div class="valtext">
              <div class="tal">场所面板设置</div>
              <div class="tel">
                <div></div>
                <div>场所管理面板顶部数据支持自定义设置</div>
              </div>
              <img src="../../assets/img/palm2_10.png" />
            </div>
          </div>
        </div>
        <div class="valback6">
          <div class="valinfo6">
            <div class="valtext">
              <div class="tal">阈值设置</div>
              <div class="tel">
                <div></div>
                <div>
                  对上座率、日营业收入、日在线支付收入设置参考值和预警值，方便数据对比和预警信息推送
                </div>
              </div>
              <img src="../../assets/img/palm2_11.png" />
            </div>
            <img src="../../assets/img/palm2_6.png" />
          </div>
        </div>
        <div class="valback7">
          <div class="valinfo7">
            <img src="../../assets/img/palm2_7.png" />
            <div class="valtext">
              <div class="tal">消息推送</div>
              <div class="tel">
                <div></div>
                <div>交班信息、敏感操作及时推送，应用临到期及时提醒</div>
              </div>
              <img src="../../assets/img/palm2_12.png" />
            </div>
          </div>
        </div>
        <div class="valback8">
          <div class="valinfo8">
            <div class="valtext">
              <div class="tal">资讯板块</div>
              <div class="tel">
                <div></div>
                <div>支持发布求购、出售、招聘等信息，了解行业最新资讯</div>
              </div>
              <div class="tel">
                <div></div>
                <div>
                  发布的招聘信息将同时推送至招聘区域下的“去上网APP”用户，为场所提供更便捷、更有效的招聘信息传播渠道
                </div>
              </div>
              <img src="../../assets/img/palm2_13.png" />
            </div>
            <img src="../../assets/img/palm2_8.png" />
          </div>
        </div>
      </el-main>
      <!-- 底部 -->
      <el-footer>
        <div class="fotter">
          <div class="fotterdiv">
            <div class="yuedong">
              <img src="../../assets/img/palmfooter.png" />
              <div class="text1">
                © Copyright 2008 yedone.com all rights reserved.
                渝网文[2013]0887-006号
              </div>
              <div class="text2">
                经营许可证：渝B2-20190234&nbsp;&nbsp;|&nbsp;&nbsp;<span @click="zhizhao">营业执照</span><br />渝ICP备09014244号-16
              </div>
              <div class="text3">
                <img src="../../assets/img/guohui.png" />
                <div class="text3tel" @click="beian">
                  渝公网安备 50010802002169号
                </div>
              </div>
            </div>
            <div class="bor">
              <div class="bor1"></div>
              <div class="bor2"></div>
            </div>
            <div class="contactUs">
              <div class="women">联系我们</div>
              <div class="phone">
                <div class="dianhua1">
                  <div>客服电话（24h）</div>
                  <div>400-680-8811</div>
                </div>
                <div class="dianhua2">
                  <div>商务电话（工作日09:00-18:00)</div>
                  <div>023-68636622</div>
                </div>
              </div>
              <div class="phone2">
                <div class="dianhua1">
                  <div>客服QQ（24h）</div>
                  <div>4006808811</div>
                </div>
                <div class="dianhua2">
                  <div>公司地址</div>
                  <div>重庆市南岸区南城大道199号正联大厦3楼</div>
                </div>
              </div>
            </div>
            <div class="attention">
              <div class="women">关注我们</div>
              <div class="erweima">
                <div class="fuwu">
                  <img src="../../assets/img/qushangwang.png" />
                  <div>去上网APP微信服务号</div>
                </div>
                <div class="gongzong">
                  <img src="../../assets/img/baquan.jpg" />
                  <div>8圈计费微信公众号</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </el-footer>
    </el-container>
  </div>
</template>
<script>
export default {
  name: "palm2",
  data() {
    return {};
  },
  methods: {
    //跳转首页
    palm0() {
      this.$router.push("/palm0");
    },
    //跳转产品介绍
    palm1() {
      this.$router.push("/palm1");
    },
    //跳转功能介绍
    palm2() {
      this.$router.push("/palm2");
    },
    //跳转下载
    palm3() {
      this.$router.push("/palm3");
    },
    //跳转备案
    beian() {
      window.open(
        "http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=50010802002169"
      );
    },
    //跳转营业执照
    zhizhao(){
      let routeData = this.$router.resolve('/yinye');
      window.open(routeData.href, '_blank');
    }
  },
  mounted() {
    if (
      /Android|webOS|iPhone|iPod|BlackBerry|Harmony/i.test(navigator.userAgent)
    ) {
      //移动设备打开的网页，缩放页面尺寸
      /*
  		var clientWidth = document.body.clientWidth;
  		var rate = clientWidth / 1920;
  		*/
      var rate = 0.1;
      var meta = document.getElementsByTagName("meta");
      meta["viewport"].setAttribute(
        "content",
        "width=device-width,initial-scale=" + rate
      );
    }
  },
};
</script>
<style lang="less" scoped>
// 页头样式
.el-header {
  width: 100%;
  min-width: 1320px;
  height: 80px !important;
  box-sizing: border-box;
  padding: 16px 0 0;
  margin: 0 auto;
  .maintitle {
    display: flex;
    align-items: center;
    width: 1200px;
    margin: 0 auto;
    > img {
      width: 148px;
      height: 48px;
      margin-right: 50px;
      &:hover {
        cursor: pointer;
      }
    }
    > div {
      width: 72px;
      height: 27px;
      text-align: center;
      font-size: 18px;
      font-family: Source Han Sans SC;
      font-weight: 500;
      line-height: 27px;
      color: #626778;
      margin-left: 80px;
      &:hover {
        cursor: pointer;
      }
    }
    .active {
      opacity: 1;
      position: relative;
      color: #0e6eff;
      &::after {
        content: "";
        position: absolute;
        width: 30px;
        height: 4px;
        background: #0e6eff;
        opacity: 1;
        bottom: -10px;
        left: 50%;
        transform: translate(-50%, 50%);
      }
    }
  }
}
.el-main {
  width: 100%;
  min-width: 1320px;
  padding: 0;
  margin: 0 auto;
  .palmmain {
    margin: 0 auto;
    height: 300px;
    background-image: url(../../assets/img/palm2back.png);
    background-size: cover;
    /* 将背景图片等比缩放填满整个容器 */
    background-repeat: no-repeat;
    background-position: center;
  }
  .valback1 {
    background: #ffffff;
    .valinfo1 {
      width: 1320px;
      margin: 0 auto;
      padding: 60px 0;
      display: flex;
      > img {
        width: 670px;
        height: 752px;
      }
      .valtext {
        margin: 137px 0 0 88px;
        .tal {
          width: 267px;
          height: 59px;
          font-size: 40px;
          font-family: Source Han Sans SC-Medium, Source Han Sans SC;
          font-weight: 500;
          color: #2d394b;
        }
        .tel {
          display: flex;
          width: 502px;
          margin-top: 30px;
          & > div:first-child {
            width: 14px;
            height: 14px;
            min-width: 14px;
            min-height: 14px;
            background: linear-gradient(135deg, #72c5ff 0%, #3d88ff 100%);
            border-radius: 50%;
            margin: 11px 8px 0 0;
          }
          & > div:last-child {
            font-size: 24px;
            line-height: 36px;
            font-family: Source Han Sans SC-Regular, Source Han Sans SC;
            font-weight: 400;
            color: #626778;
          }
        }
      }
    }
  }
  .valback2 {
    background: #f4f5f9;
    .valinfo2 {
      width: 1320px;
      margin: 0 auto;
      padding: 60px 0;
      display: flex;
      .valtext {
        margin: 181px 0 0 60px;
        .tal {
          width: 267px;
          height: 59px;
          font-size: 40px;
          font-family: Source Han Sans SC-Medium, Source Han Sans SC;
          font-weight: 500;
          color: #2d394b;
        }
        .tel {
          display: flex;
          width: 502px;
          margin-top: 30px;
          & > div:first-child {
            width: 14px;
            height: 14px;
            min-width: 14px;
            min-height: 14px;
            background: linear-gradient(135deg, #72c5ff 0%, #3d88ff 100%);
            border-radius: 50%;
            margin: 11px 8px 0 0;
          }
          & > div:last-child {
            font-size: 24px;
            line-height: 36px;
            font-family: Source Han Sans SC-Regular, Source Han Sans SC;
            font-weight: 400;
            color: #626778;
          }
        }
      }
      > img {
        width: 579px;
        height: 752px;
        margin-left: 179px;
      }
    }
  }
  .valback3 {
    background: #ffffff;
    .valinfo3 {
      width: 1320px;
      margin: 0 auto;
      padding: 60px 0;
      display: flex;
      > img {
        width: 639px;
        height: 752px;
      }
      .valtext {
        margin: 220px 0 0 119px;
        .tal {
          width: 267px;
          height: 59px;
          font-size: 40px;
          font-family: Source Han Sans SC-Medium, Source Han Sans SC;
          font-weight: 500;
          color: #2d394b;
        }
        .tel {
          display: flex;
          width: 502px;
          margin-top: 30px;
          & > div:first-child {
            width: 14px;
            height: 14px;
            min-width: 14px;
            min-height: 14px;
            background: linear-gradient(135deg, #72c5ff 0%, #3d88ff 100%);
            border-radius: 50%;
            margin: 11px 8px 0 0;
          }
          & > div:last-child {
            font-size: 24px;
            line-height: 36px;
            font-family: Source Han Sans SC-Regular, Source Han Sans SC;
            font-weight: 400;
            color: #626778;
          }
        }
      }
    }
  }
  .valback4 {
    background: #f4f5f9;
    .valinfo4 {
      width: 1320px;
      margin: 0 auto;
      padding: 60px 0;
      display: flex;
      .valtext {
        margin: 188px 0 0 60px;
        .tal {
          width: 267px;
          height: 59px;
          font-size: 40px;
          font-family: Source Han Sans SC-Medium, Source Han Sans SC;
          font-weight: 500;
          color: #2d394b;
        }
        .tel {
          display: flex;
          width: 502px;
          margin-top: 30px;
          & > div:first-child {
            width: 14px;
            height: 14px;
            min-width: 14px;
            min-height: 14px;
            background: linear-gradient(135deg, #72c5ff 0%, #3d88ff 100%);
            border-radius: 50%;
            margin: 11px 8px 0 0;
          }
          & > div:last-child {
            font-size: 24px;
            line-height: 36px;
            font-family: Source Han Sans SC-Regular, Source Han Sans SC;
            font-weight: 400;
            color: #626778;
          }
        }
        & > img {
          width: 344px;
          height: 88px;
          margin: 40px 0 0 22px;
        }
      }
      > img {
        width: 579px;
        height: 752px;
        margin-left: 139px;
      }
    }
  }
  .valback5 {
    background: #ffffff;
    .valinfo5 {
      width: 1320px;
      margin: 0 auto;
      padding: 60px 0;
      display: flex;
      > img {
        width: 620px;
        height: 752px;
      }
      .valtext {
        margin: 120px 0 0 99px;
        .tal {
          width: 267px;
          height: 59px;
          font-size: 40px;
          font-family: Source Han Sans SC-Medium, Source Han Sans SC;
          font-weight: 500;
          color: #2d394b;
        }
        .tel {
          display: flex;
          width: 502px;
          margin-top: 30px;
          & > div:first-child {
            width: 14px;
            height: 14px;
            min-width: 14px;
            min-height: 14px;
            background: linear-gradient(135deg, #72c5ff 0%, #3d88ff 100%);
            border-radius: 50%;
            margin: 11px 8px 0 0;
          }
          & > div:last-child {
            font-size: 24px;
            line-height: 36px;
            font-family: Source Han Sans SC-Regular, Source Han Sans SC;
            font-weight: 400;
            color: #626778;
          }
        }
        & > img {
          width: 576px;
          height: 359px;
          margin: 4px 0 0 -5px;
        }
      }
    }
  }
  .valback6 {
    background: #f4f5f9;
    .valinfo6 {
      width: 1320px;
      margin: 0 auto;
      padding: 60px 0;
      display: flex;
      .valtext {
        margin: 55px 0 0 60px;
        .tal {
          width: 267px;
          height: 59px;
          font-size: 40px;
          font-family: Source Han Sans SC-Medium, Source Han Sans SC;
          font-weight: 500;
          color: #2d394b;
        }
        .tel {
          display: flex;
          width: 502px;
          margin-top: 30px;
          & > div:first-child {
            width: 14px;
            height: 14px;
            min-width: 14px;
            min-height: 14px;
            background: linear-gradient(135deg, #72c5ff 0%, #3d88ff 100%);
            border-radius: 50%;
            margin: 11px 8px 0 0;
          }
          & > div:last-child {
            font-size: 24px;
            line-height: 36px;
            font-family: Source Han Sans SC-Regular, Source Han Sans SC;
            font-weight: 400;
            color: #626778;
          }
        }
        & > img {
          width: 416px;
          height: 380px;
          margin: 8px 0 0;
        }
      }
      > img {
        width: 620px;
        height: 752px;
        margin-left: 138px;
      }
    }
  }
  .valback7 {
    background: #ffffff;
    .valinfo7 {
      width: 1320px;
      margin: 0 auto;
      padding: 60px 0;
      display: flex;
      > img {
        width: 610px;
        height: 752px;
      }
      .valtext {
        margin: 140px 0 0 148px;
        .tal {
          width: 267px;
          height: 59px;
          font-size: 40px;
          font-family: Source Han Sans SC-Medium, Source Han Sans SC;
          font-weight: 500;
          color: #2d394b;
        }
        .tel {
          display: flex;
          width: 502px;
          margin-top: 30px;
          & > div:first-child {
            width: 14px;
            height: 14px;
            min-width: 14px;
            min-height: 14px;
            background: linear-gradient(135deg, #72c5ff 0%, #3d88ff 100%);
            border-radius: 50%;
            margin: 11px 8px 0 0;
          }
          & > div:last-child {
            font-size: 24px;
            line-height: 36px;
            font-family: Source Han Sans SC-Regular, Source Han Sans SC;
            font-weight: 400;
            color: #626778;
          }
        }
        & > img {
          width: 508px;
          height: 280px;
          margin: 4px 0 0 -6px;
        }
      }
    }
  }
  .valback8 {
    background: #f4f5f9;
    .valinfo8 {
      width: 1320px;
      margin: 0 auto;
      padding: 60px 0;
      display: flex;
      .valtext {
        margin: 94px 0 0 60px;
        .tal {
          width: 267px;
          height: 59px;
          font-size: 40px;
          font-family: Source Han Sans SC-Medium, Source Han Sans SC;
          font-weight: 500;
          color: #2d394b;
        }
        .tel {
          display: flex;
          width: 502px;
          margin-top: 30px;
          & > div:first-child {
            width: 14px;
            height: 14px;
            min-width: 14px;
            min-height: 14px;
            background: linear-gradient(135deg, #72c5ff 0%, #3d88ff 100%);
            border-radius: 50%;
            margin: 11px 8px 0 0;
          }
          & > div:last-child {
            font-size: 24px;
            line-height: 36px;
            font-family: Source Han Sans SC-Regular, Source Han Sans SC;
            font-weight: 400;
            color: #626778;
          }
        }
        & > img {
          width: 508px;
          height: 287px;
          margin: 8px 0 0;
        }
      }
      > img {
        width: 612px;
        height: 752px;
        margin-left: 140px;
      }
    }
  }
}
//底部
.el-footer {
  width: 100%;
  min-width: 1320px;
  margin: 0 auto;
  padding: 0;
  .fotter {
    height: 338px;
    background: #0b192b;
    padding-top: 51px;
    box-sizing: border-box;
    .fotterdiv {
      width: 1200px;
      height: 225px;
      margin: 0 auto;
      display: flex;
      .yuedong {
        width: 273px;
        height: 225px;
        font-size: 14px;
        font-family: Source Han Sans SC;
        font-weight: 400;
        line-height: 21px;
        color: #626778;
        > img {
          width: 148px;
          height: 48px;
        }
        .text1 {
          margin-top: 37px;
        }
        .text2 {
          margin-top: 14px;
          span:hover{cursor: pointer;color: #ffffff;}
        }
        .text3 {
          margin-top: 14px;
          display: flex;
          justify-items: center;
          img {
            margin-right: 10px;
          }
          .text3tel {
            &:hover {
              cursor: pointer;
              color: #ffffff;
            }
          }
        }
      }
      .bor {
        width: 1px;
        height: 225px;
        margin-left: 36px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        .bor1 {
          flex: 1;
        }
        .bor2 {
          height: 120px;
          border: 1px solid #1b2c43;
        }
      }
      .contactUs {
        width: 475px;
        height: 218px;
        margin-left: 50px;
        margin-top: 9px;
        font-size: 20px;
        font-family: Source Han Sans SC;
        font-weight: 500;
        line-height: 18px;
        color: #ffffff;
        .women {
          width: 80px;
          height: 29px;
        }
        .phone,
        .phone2 {
          margin-top: 57px;
          display: flex;
          .dianhua1,
          .dianhua2 {
            div:nth-child(1) {
              height: 20px;
              font-size: 14px;
              font-weight: 400;
              color: #626778;
            }
            div:nth-child(2) {
              height: 22px;
              font-size: 18px;
              font-family: DIN;
              color: #ffffff;
              margin-top: 8px;
            }
          }
          .dianhua1 {
            width: 124px;
          }
          .dianhua2 {
            margin-left: 52px;
          }
        }
        .phone2 {
          margin-top: 30px;
          .dianhua2 div:nth-child(2) {
            font-family: Source Han Sans SC;
            font-size: 16px;
          }
        }
      }
      .attention {
        width: 298px;
        height: 218px;
        margin-left: 67px;
        margin-top: 9px;
        font-size: 20px;
        font-family: Source Han Sans SC;
        font-weight: 500;
        line-height: 18px;
        color: #ffffff;
        .women {
          width: 80px;
          height: 29px;
          margin-left: 10px;
        }
        .erweima {
          display: flex;
          margin-top: 51px;
          .fuwu,
          .gongzong {
            font-size: 14px;
            font-weight: 400;
            color: #626778;
            text-align: center;
            > img {
              width: 110px;
              height: 110px;
            }
            > div {
              margin-top: 8px;
            }
          }
          .gongzong {
            margin-left: 38px;
          }
        }
      }
    }
  }
}
</style>
